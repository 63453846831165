import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router'
// eslint-disable-next-line import/namespace
import { useStaticQuery, graphql } from 'gatsby'

const PAGE_TITLE =
  'Hitabis GmbH                                   - Softwareentwicklung und Softwareintegration aus Berlin                    '

const SEO = ({ title, description, article }) => {
	const { pathname } = useLocation()
	const {
		mdx,
		site: {
			siteMetadata: { siteUrl },
		},
	} = useStaticQuery(query)

	const { defaultTitle, titleTemplate, defaultDescription, image } =
    mdx.frontmatter

	const defaultImage = '/icons/icon-512x512.png'

	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		image: `${siteUrl}${image || defaultImage}`,
		url: `${siteUrl}${pathname}`,
	}

	seo.longTitle = seo.title
	if (pathname === '/') {
		seo.longTitle = PAGE_TITLE
	} else {
		seo.longTitle += ' - ' + PAGE_TITLE
	}

	return (
		<Helmet title={seo.longTitle} titleTemplate={titleTemplate}>
			<meta name="description" content={seo.description} />
			<meta name="image" content={seo.image} />

			{seo.url && <meta property="og:url" content={seo.url} />}

			{(article ? true : null) && <meta property="og:type" content="article" />}

			{seo.title && <meta property="og:title" content={seo.title} />}

			{seo.description && (
				<meta property="og:description" content={seo.description} />
			)}

			{seo.image && <meta property="og:image" content={seo.image} />}

			<meta name="twitter:card" content="summary_large_image" />

			{seo.title && <meta name="twitter:title" content={seo.title} />}

			{seo.description && (
				<meta name="twitter:description" content={seo.description} />
			)}

			{seo.image && <meta name="twitter:image" content={seo.image} />}
		</Helmet>
	)
}

export default SEO

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string,
	article: PropTypes.bool,
}

SEO.defaultProps = {
	title: null,
	description: null,
	image: null,
	article: false,
}

const query = graphql`
  query SEO {
    mdx {
      frontmatter {
        defaultTitle: title
        description: subtitle
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
