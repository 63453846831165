import React from 'react'

import Logo from '../assets/images/hitabis-logo.svg'

const PAGES = require('../data/menu.json')

export default function Menu({ path }) {
	const _isCurrent = (slug) => {
		if (slug === '') {
			if (path === '/') {
				return true
			}
		} else {
			if (path && path.includes(slug)) {
				return true
			}
		}
		return false
	}
	const isCurrent = (slug) => (_isCurrent(slug) ? 'page' : '')
	const defaulClass =
    'menu-item menu-item-type-post_type menu-item-object-page'
	const isCurrentClass = (slug) =>
		_isCurrent(slug)
			? defaulClass + ' current-menu-item current_page_item '
			: defaulClass
	const menu = PAGES.map(({ slug, text, sub }) => (
		<li className={isCurrentClass(slug)} key={slug}>
			<a href={'/' + slug} aria-current={isCurrent(slug)}>
				<span>{text}</span>
			</a>
			{sub && (
				<>
					<input type="checkbox" className="submenuCheckbox" id={slug} />
					<label className="toggle-sub-menu" htmlFor={slug}></label>
					<ul className="sub-menu">
						{sub.map((subItem) => (
							<li className={isCurrentClass(subItem.slug)} key={subItem.slug}>
								<a href={'/' + subItem.slug}>
									<span>{subItem.text}</span>
								</a>
							</li>
						))}
					</ul>
				</>
			)}
		</li>
	))
	return (
		<div className="site-header-bottom gradient">
			<div className="container">
				<div className="row">
					<div className="header-column columns xs-12 sm-12 md-12 lg-12">
						<a
							className="logo"
							href="https://www.hitabis.de"
							title="Zur Startseite der Hitabis GmbH"
						>
							<img src={Logo} alt="Logo Hitabis GmbH" />
						</a>
						<input type="checkbox" className="mainMenuCheckbox" id="mainMenu" />
						<nav className="main-navi">
							<ul className="main-navi-ul">{menu}</ul>
						</nav>
						<label id="toggle-nav" htmlFor="mainMenu">
              Menü
						</label>
					</div>
				</div>
			</div>
		</div>
	)
}
