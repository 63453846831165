import React from 'react'

const PARTNERS = require('../data/partners.json')

export default function Partners() {
	const partnerImages = PARTNERS.map((partner) => `/partners/${partner}`)
	return (
		<div className="site-footer-logos">
			<div className="container">
				<h2>Unsere Partner</h2>
				<div className="slider">
					<div className="slide-track">
						{partnerImages.map((partner) => (
							<div className="slide" key={partner}>
								<img src={partner} height="74" width="184" alt="" />
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}
