import React from 'react'
import './Box.css'

const PERSONS = require('../data/people.json')

export function LeftBox({ person }) {
	const { name, image, responsibility, tel } = PERSONS[person]
	const srcSet = `${image.normal} 620w, ${image.w184} 184w, ${image.w300} 300w`
	return (
		<div className="contact-box">
			<h2>Ansprechpartner</h2>

			<div className="contact-person">
				<div className="image-wrapper">
					<img
						width="620"
						height="330"
						src={image.normal}
						className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
						alt={name}
						loading="lazy"
						srcSet={srcSet}
						sizes="(max-width: 620px) 100vw, 620px"
					/>
				</div>

				<h3>{name}</h3>

				<p>{responsibility}</p>

				<p>
          Tel.: <a href={'tel:' + tel}>{tel}</a>
				</p>
				<p className="clearfix">
					<a
						className="button"
						href="/kontakt"
						title="Nehmen Sie mit uns Kontakt auf"
					>
            Zur Kontaktseite
					</a>
				</p>
			</div>
		</div>
	)
}

export const ContactBox = () => (
	<div className="contact-box">
		<h2>Ihr Kontakt zu uns</h2>
		<p>
      Sie haben Interesse an einer Dienstleistung von uns? Wir beraten Sie
      gerne!
		</p>
		<a
			className="button"
			href="/kontakt"
			title="Nehmen Sie mit uns Kontakt auf"
		>
      Zur Kontaktseite
		</a>
	</div>
)

export const BigBox = ({ children }) => (
	<div className="columns xs-12 sm-12 md-6 lg-6">
		<div className="contact-box box-big">{children}</div>
	</div>
)

export const PartnerBox = ({ children }) => (
	<SmallBox className="partner-box">{children}</SmallBox>
)

export const RefBox = ({ children, anchor }) => (
	<SmallBox className="ref-box" anchor={anchor}>
		{children}
	</SmallBox>
)

export const TeaserBox = ({ children }) => (
	<div className="teaser-column columns xs-12 sm-12 md-6 lg-3">
		<div className="teaserbox-services">{children}</div>
	</div>
)
export const HugeBox = ({ children }) => (
	<div className="teaser-column columns xs-12 sm-12 md-12 lg-12">
		<div className="teaserbox-link-list">{children}</div>
	</div>
)

const SmallBox = ({ children, className, anchor }) => (
	<div className="columns xs-12 sm-12 md-6 lg-4" id={anchor}>
		<div className={`partner-box small-box-${className}`}>{children}</div>
	</div>
)
