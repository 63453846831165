import React from 'react'

import { LeftBox, ContactBox } from './Box'

export default function Columns({ children, left }) {
	return (
		<div className="row">
			<div className="push columns xs-12 sm-12 md-8 lg-9">
				<div className="main-content">{children}</div>
			</div>
			<div className="columns xs-12 sm-12 md-4 lg-3">
				<div className="left-sidebar">
					{left && <LeftBox person={left} />}
					{!left && <ContactBox />}
				</div>
			</div>
		</div>
	)
}

export function PlainColumn({ children }) {
	return <div className="columns xs-12 sm-12 md-6 lg-6">{children}</div>
}
