import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layoutPartials.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Rows = makeShortcode("Rows");
const TeaserBox = makeShortcode("TeaserBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Rows addClass="teaser-row-top" mdxType="Rows">
      <TeaserBox mdxType="TeaserBox">
        <p><img parentName="p" {...{
            "src": "/landing/icon_it-beratung.svg",
            "alt": "IT-Beratung"
          }}></img></p>
        <h3>{`IT-Beratung`}</h3>
        <p>{`Wir schützen Ihre Investitionen und Ihre vertraulichen Daten. Ihre IT-Sicherheit liegt uns ebenso am Herzen wie zuverlässige Zusammenarbeit.`}</p>
        <p><a parentName="p" {...{
            "href": "/portfolio/it-beratung/",
            "title": "Erfahren Sie hier mehr zum Thema"
          }}>{`mehr erfahren`}</a></p>
      </TeaserBox>
      <TeaserBox mdxType="TeaserBox">
        <p><img parentName="p" {...{
            "src": "/landing/icon_systemintegration.svg",
            "alt": "Systemintegration"
          }}></img></p>
        <h3>{`Systemintegration`}</h3>
        <p>{`Wir verbinden IT-Systeme mit technischen Anlagen. Unsere Lösungen verteilen Daten aus verschiedenen Quellen sicher und fehlerfrei weiter.`}</p>
        <p><a parentName="p" {...{
            "href": "/portfolio/systemintegration/",
            "title": "Erfahren Sie hier mehr zum Thema"
          }}>{`mehr erfahren`}</a></p>
      </TeaserBox>
      <TeaserBox mdxType="TeaserBox">
        <p><img parentName="p" {...{
            "src": "/landing/icon_softwareentwicklung.svg",
            "alt": "Softwareentwicklung"
          }}></img></p>
        <h3>{`Softwareentwicklung`}</h3>
        <p>{`Wir erstellen passgenaue Softwarelösungen für unsere Kunden. Dabei arbeiten wir plattformübergreifend und herstellerneutral.`}</p>
        <p><a parentName="p" {...{
            "href": "/portfolio/softwareentwicklung/",
            "title": "Erfahren Sie hier mehr zum Thema"
          }}>{`mehr erfahren`}</a></p>
      </TeaserBox>
      <TeaserBox mdxType="TeaserBox">
        <p><img parentName="p" {...{
            "src": "/landing/icon_it-dienstleitungen.svg",
            "alt": "IT-Dienstleistung"
          }}></img></p>
        <h3>{`IT-Dienstleistung`}</h3>
        <p>{`Wir unterstützen Sie beim Erreichen Ihrer strategischen Ziele und begleiten Sie bei der Realisierung Ihrer innovativen Ideen.`}</p>
        <p><a parentName="p" {...{
            "href": "/portfolio/it-dienstleistung/",
            "title": "Erfahren Sie hier mehr zum Thema"
          }}>{`mehr erfahren`}</a></p>
      </TeaserBox>
    </Rows>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      