import React from 'react'

import Hint from './Hint.mdx'

export default function HeaderTop() {
	const changeVal = () => {
		document.querySelector('input[name="q"]').value =
      document.querySelector('input[name="q"]').value + ' site:hitabis.de'
	}
	return (
		<div className="site-header-top gradient">
			<div className="container">
				<div className="row">
					<div className="columns xs-12 sm-12 md-12 lg-12">
						<div className="push top-navi-wrapper">
							<nav className="header-navi">
								<ul className="header-navi-ul">
									<li
										id="menu-item-87"
										className="menu-item menu-item-type-post_type menu-item-object-page menu-item-87"
									>
										<a href="/kontakt/">Kontakt</a>
									</li>
								</ul>
							</nav>
						</div>
						<form
							method="get"
							className="searchform"
							action="https://www.google.de/search"
							onSubmit={changeVal}
						>
							<input
								type="search"
								autoComplete="off"
								name="q"
								className="searchField"
								placeholder="Suche"
								defaultValue=""
							/>
							<input
								type="submit"
								className="search-submit"
								defaultValue="Suche"
							/>
						</form>{' '}
						<div
							style={{
								float: 'left',
								color: 'white',
								fontSize: '4em',
								position: 'absolute',
								paddingTop: '10px',
								top: 0,
								width: 'auto',
							}}
						>
							<Hint />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
