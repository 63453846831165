import React from 'react'

import IndexUpper from '../pages/__partials__/index.upper.partial.mdx'

export default function Section({ title, subtitle, path }) {
	const parts = path ? path.split('/') : []
	let add = []
	for (let i = 1; i < parts.length - 2; i++) {
		const part = parts[i]
		const link = parts.slice(0, i + 1).join('/')
		add.push(
			<span key={link}>
				<a href={link}>{capitalizeFirstLetter(part)}</a> »{' '}
			</span>
		)
	}
	let breadcrumbs
	if (path && path !== '/') {
		breadcrumbs = (
			<nav className="breadcrumb">
				<a href="/">Startseite</a> » {add}
				<span className="current">{title}</span>
			</nav>
		)
	}
	let addionionalContent
	if (path && path === '/') {
		addionionalContent = <IndexUpper />
	}
	return (
		<section className="top-section">
			<div className="container">
				<div className="row">
					<div className="columns xs-12 sm-12 md-12 lg-12">
						{breadcrumbs}{' '}
						<h1 className="page-title">
							{title}
							<br />
							<span
								className="subtitle"
								dangerouslySetInnerHTML={{ __html: subtitle }}
							/>
						</h1>
						{addionionalContent}
					</div>
				</div>
			</div>
		</section>
	)
}

const capitalizeFirstLetter = (string) =>
	string.charAt(0).toUpperCase() + string.slice(1)
