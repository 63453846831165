import React, { useEffect, useState } from 'react'

export const Seperator = () => (
	<span className="seperator-floating-list">♦</span>
)

export const H5 = ({ children }) => <h5 className="entry">{children}</h5>

export const Ul = ({ children }) => (
	<ul className="checked-list">{children}</ul>
)

export const Button = ({ children, title, href }) => (
	<div className="button-wrapper">
		<a
			className="button"
			target="_blank"
			rel="noreferrer"
			title={title}
			href={href}
		>
			{children}
		</a>
	</div>
)

/**
 * Verschleierung gegen Bots
 * @param {*} param0
 * @returns
 */
export const Email = (props) => {
	const [email, setEmail] = useState('')
	useEffect(() => {
		setEmail(props.email)
	}, [])
	return <a href={`mailto:${email}`}>{email}</a>
}
