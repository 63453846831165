import React from 'react'

import { Email } from './Helper'

export default function Footer() {
	return (
		<>
			<div className="site-footer-top">
				<div className="container">
					<div className="row">
						<div className="columns xs-12 sm-12 md-6 lg-6">
							<h2>Über die Hitabis GmbH</h2>
							<p>
                Seit unserer Gründung im Jahr 2004 beraten wir unsere Kunden bei
                IT-Dienstleistungen und IT-Projekten. Dabei unterstützen wir sie
                auf allen Schritten mit unserem Know-how im Bereich der
                Softwareentwicklung und -integration. So können unsere Kunden
                ihre Wettbewerbsfähigkeit steigern,&nbsp; Synergieeffekte nutzen
                und den Umsatz steigern.
							</p>
							<p>
                Dabei stehen die Sicherheit, Zuverlässigkeit und Produktivität
                der Geschäftsprozesse unserer Kunden an erster Stelle.
							</p>
							<p>
                All dies erreichen wir, indem wir IT-Lösungen entwickeln, die
                individuell auf jeden einzelnen Kunden zugeschnitten sind.
							</p>
							<ul className="link-list">
								<li>
									<a href="/unternehmen/geschaeftsfuehrung/">
                    Geschäftsführung
									</a>
								</li>
								<li>
									<a href="/unternehmen/geschichte-profil/">
                    Geschichte/Profil
									</a>
								</li>
							</ul>
						</div>
						<div className="columns xs-12 sm-12 md-6 lg-6">
							<h2>Sprechen Sie uns an!</h2>
							<address>
								<strong>Hitabis GmbH</strong>
								<br />
                Hildegard-Jadamowitz-Straße 26
								<br />
                10243 Berlin
							</address>
							<p>
                Tel.: <a href="tel:+493028599044">+49(0)30 28 59 90 44</a>
								<br />
                Fax: +49(0)30 28 59 90 45
								<br />
                E-Mail: <Email email="info@hitabis.de" />
							</p>
							<p>
                Interesse an unseren Services?
								<br />
                Senden Sie uns eine Nachricht. Wir rufen Sie gern zurück.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="site-footer-bottom">
				<div className="container">
					<div className="row">
						<div className="columns xs-12 sm-12 md-12 lg-12">
							<nav className="footer-navi">
								<ul className="footer-navi-ul">
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-88">
										<a href="/impressum/">Impressum</a>
									</li>
									<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-89">
										<a href="/datenschutz/">Datenschutz</a>
									</li>
								</ul>{' '}
							</nav>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
