import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { Message } from 'theme-ui'

import HeaderTop from './HeaderTop'
import Menu from './Menu'
import Section from './Section'
import Content from './Content'
import './layout.css'
import './slide.css'
import Partners from './Partners'
import Footer from './Footer'
import Columns, { PlainColumn } from './Columns'
import Rows from './Rows'
import Seo from './Seo'
import { LeftBox, BigBox, PartnerBox, RefBox, TeaserBox, HugeBox } from './Box'
import { H5, Ul, Seperator, Button, Email } from './Helper'

export const shortcodes = {
	Message,
	ul: Ul,
	h5: H5,
	Columns,
	LeftBox,
	BigBox,
	Rows,
	PlainColumn,
	PartnerBox,
	Seperator,
	RefBox,
	Button,
	TeaserBox,
	HugeBox,
	Email,
}


export default function Layout({ location: { pathname }, children, pageContext }) {
	const { frontmatter } = pageContext
	return (
		<div className={pageClassName(pathname, frontmatter)}>
			<Seo {...frontmatter} />
			<div className="site-wrapper">
				<header className="site-header">
					<HeaderTop />
					<Menu path={pathname} />
				</header>
				<main>
					<Section {...frontmatter} path={pathname} />
					<Content>
						<MDXProvider components={shortcodes}>{children}</MDXProvider>
					</Content>
				</main>
				<footer className="site-footer">
					<Partners />
					<Footer />
				</footer>
			</div>
		</div>
	)
}

const pageClassName = (path, frontmatter) => {

	let pageIdClass = 'page-template-default page page-parent'
	if (!frontmatter || !path) {
		return pageIdClass
	}
	if (frontmatter.page_id) {
		pageIdClass += ' page-id-' + frontmatter.page_id
	}
	if (path.split('/').length > 3) {
		pageIdClass += ' page-child'
	} else {
		pageIdClass += ' page-parent'
	}
	if (path === '/') {
		pageIdClass += ' home'
	}

	return pageIdClass
}